export default [
	{
		title: "Home",
		route: "home",
		icon: "HomeIcon",
	},
	{
		title: "Enigma Bank",
		route: { name: "scriptPage", params: { scriptName: "enigma_bank" } },
		icon: "CodeIcon",
	},
]
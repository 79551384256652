<script>
import {
	BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BSkeleton, BSkeletonWrapper
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import useJwt from '@/auth/jwt/useJwt';
import { initialAbility } from '@/libs/acl/config'
import { getAuth, signOut } from "firebase/auth";

export default {
	data() {
		return {
			info: {},
			loading: true,
		}
	},
	components: {
		BLink,
		BNavbarNav,
		BNavItemDropdown,
		BDropdownItem,
		BDropdownDivider,
		BAvatar,
		BSkeleton,
		BSkeletonWrapper,

		// Navbar Components
		DarkToggler,
	},
	props: {
		toggleVerticalMenuActive: {
			type: Function,
			default: () => { },
		},
	},
	methods: {
		logout() {
			// Remove userData from localStorage
			// ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
			localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
			localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

			// Remove userData from localStorage
			localStorage.removeItem('userData');
			localStorage.removeItem('token');

			// Reset abilityF
			// this.$ability.update(initialAbility);

			// sign out 
			var auth = getAuth();
			signOut(auth);

			// Redirect to login page
			this.$router.push({ name: "login" });
		},
		capitalizeFirstLetter(string) {
			return string.charAt(0).toUpperCase() + string.slice(1);
		}
	},
	async mounted() {
		var ctx = this;
		ctx.info = ctx.$getUser();
		ctx.loading = false;
	},
}
</script>


<template>
	<div class="navbar-container d-flex content align-items-center">

		<!-- Nav Menu Toggler -->
		<ul class="nav navbar-nav d-xl-none">
			<li class="nav-item">
				<b-link class="nav-link" @click="toggleVerticalMenuActive">
					<feather-icon icon="MenuIcon" size="21" />
				</b-link>
			</li>
		</ul>

		<!-- Left Col -->
		<div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
			<dark-Toggler class="d-none d-lg-block" />
		</div>

		<b-navbar-nav class="nav align-items-center ml-auto">
			<b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link"
				class="dropdown-user">
				<template #button-content v-if="info">
					<div class="d-sm-flex d-none user-nav">
						<p class="user-name font-weight-bolder mb-0" :style="`${loading == true ? 'width: 60px' : ''}`">
							<b-skeleton-wrapper :loading="loading">
								<template #loading>
									<b-skeleton width="100%"></b-skeleton>
								</template>
								<div v-if="info.displayName">{{ info.displayName }}</div>
								<div v-else-if="info.email">{{ capitalizeFirstLetter(info.email.split("@")[0]) }}</div>
							</b-skeleton-wrapper>
						</p>

						<!-- <span class="user-status">Admin</span> -->
					</div>
					<b-avatar v-if="info.photoURL" size="40" variant="light-primary" badge :src="info.photoURL"
						class="badge-minimal" badge-variant="success" />
					<b-avatar v-else size="40" variant="light-primary" badge src="" class="badge-minimal"
						badge-variant="success" />
				</template>

				<b-dropdown-item link-class="d-flex align-items-center">
					<feather-icon size="16" icon="UserIcon" class="mr-50" />
					<span>Profile</span>
				</b-dropdown-item>

				<b-dropdown-item link-class="d-flex align-items-center">
					<feather-icon size="16" icon="MailIcon" class="mr-50" />
					<span>Inbox</span>
				</b-dropdown-item>

				<b-dropdown-item link-class="d-flex align-items-center">
					<feather-icon size="16" icon="CheckSquareIcon" class="mr-50" />
					<span>Task</span>
				</b-dropdown-item>

				<b-dropdown-item link-class="d-flex align-items-center">
					<feather-icon size="16" icon="MessageSquareIcon" class="mr-50" />
					<span>Chat</span>
				</b-dropdown-item>

				<b-dropdown-divider />

				<b-dropdown-item link-class="d-flex align-items-center" @click="logout">
					<feather-icon size="16" icon="LogOutIcon" class="mr-50" />
					<span>Logout</span>
				</b-dropdown-item>
			</b-nav-item-dropdown>
		</b-navbar-nav>
	</div>
</template>